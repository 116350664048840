<script>
import HorizontalMenu from '@/components/general/HorizontalMenu'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import ModalContentListAssociationVue from '@/components/contents/ModalContentListAssociation.vue'
export default {
  components: {
    HorizontalMenu,
    Breadcrumbs,
    ModalContentListAssociationVue
  },
  name: 'IndividualContents',
  data () {
    return {
      selectedMenu: 0,
      showAddList: false,
      contentId: null
    }
  },
  computed: {
    menuOptions () {
      return [
        { to: { name: this.selectedWorkspace.type + '.home.user.contents.published' }, text: 'individual:contents', icon: 'mdi-file' },
        { to: { name: this.selectedWorkspace.type + '.contents.lists' }, text: 'individual:lists', icon: 'mdi-format-list-bulleted' },
        { to: { name: this.selectedWorkspace.type + '.home.user.contents.favorites' }, text: 'individual:saved', icon: 'mdi-heart' },
        { to: { name: this.selectedWorkspace.type + '.home.user.contents.in.progress' }, text: 'individual:in.progress', icon: 'mdi-play-circle' },
        { to: { name: this.selectedWorkspace.type + '.home.user.contents.finished' }, text: 'individual:concluded', icon: 'mdi-checkbox-marked-circle' },
        { to: { name: this.selectedWorkspace.type + '.home.user.contents.enrolled' }, text: 'individual:enrolled', icon: 'mdi-checkbox-marked-circle' }
      ]
    },
    breadcrumbsPaths () {
      const path = [{ text: 'main.header:contents', href: this.$route.path }]
      return path
    }
  },
  methods: {
    showModalList (params) {
      this.contentId = params
      this.showAddList = !this.showAddList
    }
  }
}
</script>
<template>
  <div class="individual-contents--container">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths" />
      <h2>{{ $t("contents.list.page:title") }}</h2>
    </div>
    <div class="individual-contents--body">
      <horizontal-menu :options="menuOptions" v-model="selectedMenu"></horizontal-menu>
      <router-view @showModalAddListContent="params => showModalList(params)"></router-view>
      <modal-content-list-association-vue v-if="showAddList" @hideModalAddListContent="showModalList()"
            :contentIdProp="contentId"></modal-content-list-association-vue>
    </div>
  </div>
</template>
<style lang="scss">
.individual-contents--container {
  .skeleton-contents {
    display: flex;

    .skeleton-card-contents {
      margin-right: 20px;
    }
  }
  .individual-contents--body {
    text-align: left;

    .individual-contents--list-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .content-card--container {
        flex: 0 0 33.333333%;
      }
    }
  }

}
</style>
